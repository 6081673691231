// ********************************************************************************************************************
// 用户后台主页
const My = () => import('views/my/My')
const MyProfile = () => import('views/my/MyProfile')
const MyOAuth = () => import('views/my/MyOAuth')
const MyRcmd = () => import('views/my/MyRcmd')
const MyMessage = () => import('views/my/MyMessage')
const MyRecord = () => import('views/my/MyRecord')
const MyVip = () => import('views/my/MyVip')
const MyWallet = () => import('views/my/MyWallet')

export default {
  path: '/my',
  name: 'My',
  component: My,
  meta: { needAuth: true },
  children: [
    {
      path: '',
      name: '个人资料',
      component: MyProfile,
      meta: { needAuth: true }
    },
    {
      path: '/my/vip',
      name: '小会员',
      component: MyVip,
      meta: { needAuth: true }
    },
    {
      path: '/my/wallet',
      name: '钱包',
      component: MyWallet,
      meta: { needAuth: true }
    },
    {
      path: '/my/record',
      name: '我的登录历史',
      component: MyRecord,
      meta: { needAuth: true }
    },
    {
      path: '/my/message',
      name: '我的消息',
      component: MyMessage,
      meta: { needAuth: true }
    },
    {
      path: '/my/oauth',
      name: 'OAuth',
      component: MyOAuth,
      meta: { needAuth: true }
    },
    {
      path: '/my/rcmd',
      name: 'Rcmd',
      component: MyRcmd,
      meta: { needAuth: true }
    }
  ]
}
