import VueRouter from 'vue-router'
import Vue from 'vue'
import MyRouter from './my'
import UserRouter from './user'
import ExamRouter from './exam'
import MallRouter from './mall'
import VodRouter from './vod'
import MapRouter from './map'

// 懒加载引入页面组件，es6语法
// ********************************************************************************************************************
const Login = () => import('views/Login')
const Register = () => import('views/Register')
const Forgot = () => import('views/Forgot')
const Index = () => import('views/Index')

const Home = () => import('views/home/Home')
const TimelineIndex = () => import('views/home/Timeline')
const VideoIndex = () => import('views/home/Video')
const VideoTag = () => import('views/home/VideoTag')
const ShortVideoIndex = () => import('views/home/ShortVideo')
const VideoPage = () => import('views/home/VideoPage')
const AudioIndex = () => import('views/home/Audio')
const AudioPage = () => import('views/home/AudioPage')
const ImagePage = () => import('views/home/ImagePage')
const ArticleIndex = () => import('views/home/Article')
const ArticlePage = () => import('views/home/ArticlePage')
const Chat = () => import('views/home/Chat')
const Search = () => import('views/home/Search')
const PlaylistIndex = () => import('views/home/PlaylistIndex')
const PlaylistView = () => import('views/home/PlaylistView')

// ********************************************************************************************************************
// 使用安装路由插件
Vue.use(VueRouter)
const routes = [
  MyRouter,
  ExamRouter,
  UserRouter,
  MallRouter,
  MapRouter,
  VodRouter,
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { needAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { needAuth: false }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
    meta: { needAuth: false }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { needAuth: false }
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: { needAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: { needAuth: false }
      },
      {
        path: '/timeline',
        name: 'TimelineIndex',
        component: TimelineIndex,
        meta: { needAuth: true }
      },
      {
        path: '/chat',
        name: 'Chat',
        component: Chat,
        meta: { needAuth: false }
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
        meta: { needAuth: false }
      },
      {
        path: '/shortvideo',
        name: 'ShortVideoIndex',
        component: ShortVideoIndex,
        meta: { needAuth: false }
      },
      {
        path: '/video',
        name: 'VideoIndex',
        component: VideoIndex,
        meta: { needAuth: false }
      },
      {
        path: '/video/:id',
        name: 'VideoPage',
        component: VideoPage,
        meta: { needAuth: false }
      },
      {
        path: '/video/tag/:tag',
        name: 'VideoTag',
        component: VideoTag,
        meta: { needAuth: false }
      },
      {
        path: '/audio',
        name: 'AudioIndex',
        component: AudioIndex,
        meta: { needAuth: false }
      },
      {
        path: '/audio/:audioId',
        name: 'AudioPage',
        component: AudioPage,
        meta: { needAuth: false }
      },
      {
        path: '/image/:albumId',
        name: 'ImagePage',
        component: ImagePage,
        meta: { needAuth: false }
      },
      {
        path: '/article',
        name: 'ArticleIndex',
        component: ArticleIndex,
        meta: { needAuth: false }
      },
      {
        path: '/article/:articleId',
        name: 'ArticlePage',
        component: ArticlePage,
        meta: { needAuth: false }
      },
      {
        path: '/playlist',
        name: 'PlaylistIndex',
        component: PlaylistIndex,
        meta: { needAuth: false }
      },
      {
        path: '/playlist/:albumId',
        name: 'PlaylistView',
        component: PlaylistView,
        meta: { needAuth: false }
      }
    ]
  }
]

// 创建路由对象
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// 导出router
export default router
